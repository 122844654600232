import React, { FC } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import { isSSG } from '../utils';

type SEOProps = {
  description?: string;
  lang?: string;
  meta?:
    | { property: string; content: string }[]
    | { name: string; content: string }[];
  keywords?: string[];
  title?: string;
  page?: string;
  image?: string;
};

const SEO: FC<SEOProps> = ({
  description = 'Important information about upper tract urothelial cancer (UTUC), including treatment options, questions to ask your urologist, and more. UTUC could also be called cancer of the upper urinary tract, ureteral cancer, or cancer in the lining of the kidney',
  lang = 'en',
  meta = [],
  keywords = [],
  title = 'UTUC | Upper Tract Urothelial Cancer: What It Is and Your Treatment Options',
  page = '',
  image = 'https://utuc.com/images/utuc-share.jpg ',
}) => {
  const metaKeywords = keywords.length ? keywords.join(', ') : '';
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:image',
          content: image,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
        {
          name: 'keywords',
          content: metaKeywords,
        },
      ].concat(meta)}
    >
      {!isSSG && (
        <script src="https://cmp.osano.com/169siITFGHued2Kw/b5df1861-3d76-405a-9699-9bc701a530f0/osano.js"></script>
      )}
      <body className={page ? page : ''} />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'en',
  meta: [],
  keywords: [],
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
};

export default SEO;
