import React, { useState, useEffect, ReactNode, FC } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import balanceText from 'balance-text';
import 'typeface-source-sans-pro';
import SEO from './seo';
import Header from './header';
import Footer from './footer';
import MobileNav from './mobileNav/mobileNav';

import 'bootstrap/dist/css/bootstrap.min.css';

import './layout.scss';

type LayoutProps = {
  children?: ReactNode;
  doHideFooter?: boolean;
  metaTitle?: string;
  metaDescription?: string;
  metaKeywords?: string[];
  ogImage?: string;
  page?: string;
};

const Layout: FC<LayoutProps> = ({
  children,
  doHideFooter,
  metaDescription,
  metaTitle,
  ogImage,
  metaKeywords,
  page,
}) => {
  const [navActive, setNavActive] = useState(false);

  const [footerHidden, setFooterHidden] = useState(false);

  useEffect(() => {
    if (doHideFooter) {
      setFooterHidden(true);
    } else {
      setFooterHidden(false);
    }
  }, [doHideFooter]);

  useEffect(() => {
    balanceText();
  }, []);

  const handleMobileNavToggle = () => {
    setNavActive(true);
  };

  const handleMobileNavClose = () => {
    setNavActive(false);
  };

  return (
    <div
      className={`site-container ${navActive ? 'mobile-nav-active' : ''} ${
        footerHidden ? 'footer-hidden' : ''
      }`}
    >
      <SEO
        image={ogImage}
        title={metaTitle}
        description={metaDescription}
        keywords={metaKeywords}
        page={page}
      />
      <Header onMobileNavToggle={handleMobileNavToggle} />
      <main className="site-main">{children}</main>
      <Footer />
      <MobileNav
        onMobileNavClose={handleMobileNavClose}
        navActive={navActive}
      />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
